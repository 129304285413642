<template>
  <div style="background: #f5f5f5;">
    <headers />
    <div class="cmpWmV"></div>
    <div class="content">
      <div id="expert" class="title">团队介绍</div>
      <div class="expert">
        <div class="experts">
          <img :src="require('@/assets/image/class/zj11.png')" alt="" />
          <div class="introduce">
            <p class="name">董萌萌<span class="c">创始人</span></p>
            硬科技投资领域专家<br/>
            新加坡自在资本董事长<br/>
            Family office 管理者，管理数十亿美金资产
          </div>
        </div>
        <div class="experts">
          <img :src="require('@/assets/image/class/zj2.png')" alt="" />
          <div class="introduce">
            <p class="name">胡颖俊<span class="c">创始人</span></p>
            Acxiom、Murata等知名外企大数据和智能制造<br/>
            产品研发、数据分析及大型团队管理经验<br/>
            产品专家<br/>
            数据专家<br/>
            连续创业者
          </div>
        </div>        
        <div class="experts">
          <img :src="require('@/assets/image/class/zj3.png')" alt="" />
          <div class="introduce">
            <p class="name">姜继春</p>
            高级IT技术专家，数据服务专家，工业互联网技术专家。
          </div>
        </div>
        <div class="experts">
          <img :src="require('@/assets/image/class/zj4.png')" alt="" />
          <div class="introduce">
            <p class="name">任恒利</p>
            互联网技术专家，擅长人工智能、大数据、物联网、区块链等技术的的研发和应用。
          </div>
        </div>
        <div class="experts">
          <img :src="require('@/assets/image/class/zj5.png')" alt="" />
          <div class="introduce">
            <p class="name">石玉熙</p>
            产品及项目专家、吉林大学博士、多年工业互联网创业经验、电机、泵、家具等多行业数字化转型辅导专家、多次主导区域型数字化转型项目、主导参加多项省级国家级大赛并获奖。
          </div>
        </div>
        <div class="experts">
          <img :src="require('@/assets/image/class/zj6.png')" alt="" />
          <div class="introduce">
            <p class="name">李华伟</p>
            工业现场专家。二十年大型外企和国企技术研发和制造现场管理经验。擅长产品全流程管理，长年研发流程和工厂应用工程经验，对工业数据流、制造产线、虚拟仿真、数字孪生等内容具有丰富经验。
          </div>
        </div>
        <div class="experts">
          <img :src="require('@/assets/image/class/zj7.png')" alt="" />
          <div class="introduce">
            <p class="name">郭兵</p>
            工业数据和市场专家。20年知名企业和上市公司从业经验，对企业信息化、数字化营销具有大量实战经验。
          </div>
        </div>
        <div class="experts">
          <img :src="require('@/assets/image/class/zj8.png')" alt="" />
          <div class="introduce">
            <p class="name">李德明</p>
            清华大学双聘研究员。曾荣获工信部年度最佳安防设计方案奖，参与中国工程院“碳排放全生命周期与新能源汽车暗夜发展相关性研究”等课题。对企业科技类项目申报、如绿色工厂等进行全面辅导和指导。
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div id="classes" class="title">数壤小课堂</div>
      <div class="expert">
        <img class="ad" :src="require('@/assets/image/class/class3.png')" alt="" />
        <div class="classBox">
          <div class="className">未来导向——元宇宙专场</div>
          <div class="classes"><video controls loop="" preload="meta"><source src="https://digiland-static.obs.cn-east-3.myhuaweicloud.com/dm-dgland/class1.mp4" type="video/mp4"></video></div>
        </div>
        <div class="classBox">
          <div class="className">节能节费——能源与双碳</div>
          <div class="classes"><video controls loop="" preload="meta"><source src="https://digiland-static.obs.cn-east-3.myhuaweicloud.com/dm-dgland/class2.mp4" type="video/mp4"></video></div>
        </div>
        <div class="classBox">
          <div class="className">数字课堂——数字化转型</div>
          <div class="classes"><video controls loop="" preload="meta"><source src="https://digiland-static.obs.cn-east-3.myhuaweicloud.com/dm-dgland/class3.mp4" type="video/mp4"></video></div>
        </div>
      </div>
    </div>
    <div class="content">
      <div id="courses" class="title">数壤精品课(待更新）</div>
      <div class="expert">
        <img class="ad" :src="require('@/assets/image/class/class2.png')" alt="" />
      </div>
    </div>
    <div class="content" style="margin-bottom: 40px;">
      <div id="institute" class="title">数壤研究院（待更新）</div>
      <div class="expert">
        <img class="ad" :src="require('@/assets/image/class/class1.png')" alt="" />
      </div>
    </div>
    <footers />
  </div>
</template>
<script>
import headers from '@/components/head';
import footers from '@/components/foot'
import scrollMixins from "@/utils/scrollMixins";
import redirect from "@/utils/redirect";
import pcRem from "@/utils/pcrem";
export default {
  mixins: [scrollMixins,redirect,pcRem],
  components: {
    headers,
    footers
  },
  data() {
    return {
      hendImgHeight: 0
    }
  },
  created() {
    this.hendImgHeight = window.innerWidth / (1920 / 518)
  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>
//1920*1080为设计稿，头部字体大小为25px
$ratio: 1920 / 76.8; // 缩放比   25

// 返回对应屏幕下的rem值
@function px2rem($px) {
  @return $px / $ratio +rem;
}

.cmpWmV {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  color: white;
  font-weight: 300;
  background: url('~@/assets/image/class/hender.png') #f5f5f5 no-repeat;
  background-size: contain;
  background-attachment: fixed;
  width: 100%;
  height: px2rem(518);
}

.content {
  margin: px2rem(40) auto 0;
  width: px2rem(1200);
  background: #fff;
  border-radius: px2rem(2);

  .title {
    padding-left: px2rem(30);
    height: px2rem(84);
    line-height: px2rem(84);
    border-bottom: 1px solid #E9E9E9;
    font-size: px2rem(26);
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }

  .expert {
    padding: px2rem(20) px2rem(30);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .experts {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: px2rem(30);
      margin: px2rem(10) 0;
      width: px2rem(561);
      height: px2rem(318);
      background: #F5F5F5;
      box-sizing: border-box;

      img {
        width: px2rem(251);
        height: px2rem(258);
      }

      .introduce {
        margin-left: px2rem(20);
        font-size: px2rem(20);
        line-height: px2rem(24);
        color: rgba(0, 0, 0, 0.65);

        .name {
          margin-bottom: px2rem(20);
          font-size: px2rem(26);
          font-weight: 600;
          color: #308971;
          line-height: px2rem(24);
          .c{
            margin-left:px2rem(10);
            font-size: px2rem(20);
            font-weight: 400;
          }
        }
      }
    }
  }
  .ad{
    width: px2rem(1141);
    height: px2rem(318);
  }
  .classBox{
    margin-top: px2rem(30);
    width: px2rem(1141);
    height: px2rem(719);
    background: #FFFFFF;
    border-radius: px2rem(4) px2rem(4) 0px 0px;
    border: 1px solid #E9E9E9;
    .className{
      padding-left: px2rem(20);
      width: px2rem(1141);
      height: px2rem(60);
      line-height: px2rem(60);
      background: #FAFAFA;
      border-radius: px2rem(3) px2rem(3) 0px 0px;
      border-bottom: 1px solid #E9E9E9;
      font-size: px2rem(20);
      box-sizing: border-box;
    }
    .classes{
      padding: px2rem(20);
      video {    
    height: px2rem(619);
    width: px2rem(1101);
}
    }
  }
}
</style>
